import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogWrapperComponent } from '@global-shared/components/dialog-wrapper/dialog-wrapper.component';

@Component({
  selector: 'app-pin-failed-dialog',
  templateUrl: './pin-failed-dialog.component.html',
  styleUrls: ['./pin-failed-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DialogWrapperComponent],
})
export class PinFailedDialogComponent {
  matDialogRef = inject(MatDialogRef<PinFailedDialogComponent>);

  onSaveClick() {
    this.matDialogRef.close();
  }
}
