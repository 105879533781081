<app-dialog-wrapper
  [cancelButtonText]="'CANCEL'"
  [saveButtonText]="'SAVE'"
  (cancelClick)="onCancelClick()"
  (saveClick)="onSaveClick()"
  [saveDisabled]="form.invalid"
  [saveButtonTestId]="testIds.MainProjectDialogSaveButton"
  [cancelButtonTestId]="testIds.MainProjectDialogCancelButton"
>
  <div [testId]="testIds.MainProjectDialogTitle" class="headlines-h3 greyscale-darkest-grey" title-projection>
    New Main Project Group
  </div>
  <form class="px-56 py-24 flex-column" flexGap="16px" [formGroup]="form">
    <div class="flex-row" flexGap="16px">
      <div class="flex-column flex">
        <ngx-q360-text-input
          [testId]="testIds.MainProjectDialogNameInput"
          placeholder="Main project group name"
          label="Main project group name"
          [formControl]="controls.name"
          inputType="input"
          [error]="controls.name.touched && controls.name.invalid"
        />
        <ngx-q360-input-message [control]="controls.name" />
      </div>

      <div class="flex-column">
        <ngx-q360-text-input
          [testId]="testIds.MainProjectDialogShortNameInput"
          placeholder="2-5 characters"
          label="Short name"
          [formControl]="controls.code"
          inputType="input"
          [error]="controls.code.touched && controls.code.invalid"
        />
        <ngx-q360-input-message [control]="controls.code" />
      </div>
    </div>

    <div class="flex-column">
      <ngx-q360-select-input
        [sasUrl]="sasUrl()"
        [testId]="testIds.MainProjectDialogPurposeInput"
        placeholder="Select a purpose"
        label="Main project group purpose"
        [formControl]="controls.mainProjectType"
        inputType="input"
        [error]="controls.mainProjectType.touched && controls.mainProjectType.invalid"
        [items]="mainProjectTypes"
      />
      <ngx-q360-input-message [control]="controls.mainProjectType" />
    </div>
  </form>
</app-dialog-wrapper>
