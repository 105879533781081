import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ControlConfigMap, ModelFormGroup } from '@core/types/helper-types';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  CreateMainProjectPayload,
  FlexGapDirective,
  FormUtility,
  InputMessageComponent,
  MAIN_PROJECT_TYPES,
  PmSelectInputComponent,
  PmTextInputComponent,
  TestIdDirective,
  TestIds,
} from 'ngx-q360-lib';
import { CreateMainProject } from '@app/store/project/project.actions';
import { select, Store } from '@ngxs/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UserSelectors } from '@app/store/user/user.selectors';
import { DialogWrapperComponent } from '@global-shared/components/dialog-wrapper/dialog-wrapper.component';

@Component({
  selector: 'app-create-main-project-dialog',
  templateUrl: './create-main-project-dialog.component.html',
  styleUrls: ['./create-main-project-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DialogWrapperComponent,
    TestIdDirective,
    FormsModule,
    FlexGapDirective,
    ReactiveFormsModule,
    PmTextInputComponent,
    InputMessageComponent,
    PmSelectInputComponent,
  ],
})
export class CreateMainProjectDialogComponent implements OnInit {
  sasUrl = select(UserSelectors.sasRealUrlForImages);
  protected readonly testIds = TestIds;
  protected readonly mainProjectTypes = MAIN_PROJECT_TYPES;
  private destroyRef = inject(DestroyRef);

  matDialogRef = inject(MatDialogRef<CreateMainProjectDialogComponent>);
  fb = inject(FormBuilder);
  store = inject(Store);

  form: ModelFormGroup<CreateMainProjectPayload> = this.fb.group<ControlConfigMap<CreateMainProjectPayload>>({
    name: ['', [FormUtility.requiredValidator(), FormUtility.maxLengthValidator(100)]],
    code: ['', [FormUtility.requiredValidator(), FormUtility.minLengthValidator(2), FormUtility.maxLengthValidator(5)]],
    mainProjectType: [null, [FormUtility.requiredValidator()]],
  }) as ModelFormGroup<CreateMainProjectPayload>;

  ngOnInit() {
    this.controls.name.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
      this.controls.code.setValue(this.generateCode(value));
    });
  }

  get controls() {
    return this.form.controls;
  }

  onSaveClick() {
    this.store.dispatch(new CreateMainProject(this.form.getRawValue()));
    this.matDialogRef.close(true);
  }

  onCancelClick() {
    this.matDialogRef.close();
  }

  private generateCode(value: string): string {
    const words = value.split(' ').filter((el) => el?.length);
    if (words.length === 1) {
      return words[0].substring(0, 4).toUpperCase();
    }
    return words
      .slice(0, 4)
      .map((s) => s[0].toUpperCase())
      .join('');
  }
}
